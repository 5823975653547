export const cricketMatches = [
  {
    game: "cricket",
    allMatches: [
      {
        id: 0,
        time: "02 Feb 9:00",
        name: "Match 1",
        team1: {
          name: "India",
          back: 2.5,
          lay: 2.7,
        },
        team2: {
          name: "Australia",
          back: 1.9,
          lay: 2.1,
        },
        fancy: [
          { overs: "10 overs", no: 32, yes: 33 },
          { overs: "20 overs", no: 47, yes: 48 },
          { overs: "30 overs", no: 60, yes: 61 },
          { overs: "40 overs", no: 79, yes: 80 },
          { overs: "50 overs", no: 88, yes: 89 },
        ],
      },
      {
        id: 1,
        time: "02 Feb 9:00",

        name: "Match 2",
        team1: {
          name: "England",
          back: 2.3,
          lay: 2.5,
        },
        team2: {
          name: "South Africa",
          back: 1.7,
          lay: 1.9,
        },
        fancy: [
          { overs: "10 overs", no: 33, yes: 34 },
          { overs: "20 overs", no: 45, yes: 46 },
          { overs: "30 overs", no: 68, yes: 69 },
          { overs: "40 overs", no: 75, yes: 76 },
          { overs: "50 overs", no: 89, yes: 90 },
        ],
      },
      {
        id: 2,
        time: "02 Feb 9:00",

        name: "Match 3",
        team1: {
          name: "Pakistan",
          back: 2.6,
          lay: 2.8,
        },
        team2: {
          name: "New Zealand",
          back: 2.0,
          lay: 2.2,
        },
        fancy: [
          { overs: "10 overs", no: 12, yes: 13 },
          { overs: "20 overs", no: 24, yes: 25 },
          { overs: "30 overs", no: 46, yes: 47 },
          { overs: "40 overs", no: 49, yes: 50 },
          { overs: "50 overs", no: 58, yes: 59 },
        ],
      },
      {
        id: 3,
        time: "02 Feb 9:00",

        name: "Match 4",
        team1: {
          name: "West Indies",
          back: 2.2,
          lay: 2.4,
        },
        team2: {
          name: "Sri Lanka",
          back: 1.6,
          lay: 1.8,
        },
        fancy: [
          { overs: "10 overs", no: 10, yes: 11 },
          { overs: "20 overs", no: 46, yes: 47 },
          { overs: "30 overs", no: 30, yes: 31 },
          { overs: "40 overs",  no: 49, yes: 50 },
          { overs: "50 overs",  no: 75, yes: 76 },
        ],
      },
      {
        id: 4,
        time: "02 Feb 9:00",

        name: "Match 5",
        team1: {
          name: "Bangladesh",
          back: 2.7,
          lay: 2.9,
        },
        team2: {
          name: "Afghanistan",
          back: 2.1,
          lay: 2.3,
        },
        fancy: [
          { overs: "10 overs", no: 10, yes: 11 },
          { overs: "20 overs", no: 24, yes: 25 },
          { overs: "30 overs", no: 30, yes: 31 },
          { overs: "40 overs", no: 40, yes: 41 },
          { overs: "50 overs", no: 50, yes: 51 },
        ],
      },
      {
        id: 5,
        time: "02 Feb 9:00",

        name: "Match 6",
        team1: {
          name: "Zimbabwe",
          back: 2.4,
          lay: 2.6,
        },
        team2: {
          name: "Ireland",
          back: 1.8,
          lay: 2.0,
        },
        fancy: [
          { overs: "10 overs", no: 10, yes: 11 },
          { overs: "20 overs", no: 46, yes: 47 },
          { overs: "30 overs", no: 30, yes: 31 },
          { overs: "40 overs",  no: 49, yes: 50 },
          { overs: "50 overs",  no: 75, yes: 76 },
        ],
      },
    ],
  },
];

export const tennisMatches = [
  {
    game: "tennis",
    allMatches: [
      {
        id:0,
        name: "Match 1",
        time: "02 Feb 9:00",

        team1: {
          name: "Roger Federer",
          back: 1.8,
          lay: 2.0,
        },
        team2: {
          name: "Rafael Nadal",
          back: 2.2,
          lay: 2.4,
        },
      },
      {
        id:1,
        name: "Match 2",
        time: "02 Feb 9:00",

        team1: {
          name: "Novak Djokovic",
          back: 1.9,
          lay: 2.1,
        },
        team2: {
          name: "Andy Murray",
          back: 2.1,
          lay: 2.3,
        },
      },
      {
        id:2,
        name: "Match 3",
        time: "02 Feb 9:00",

        team1: {
          name: "Serena Williams",
          back: 1.7,
          lay: 1.9,
        },
        team2: {
          name: "Simona Halep",
          back: 2.3,
          lay: 2.5,
        },
      },
    ],
  },
];
export const teams = [
  {
    name: "Bangaladesh",
    back: 2.4,
    lay: 2.6,
  },
  {
    name: "Srilanka",
    back: 1.8,
    lay: 2.0,
  },
];

export const overData = [
  {
    order: 1,
    overType: "10 Overs",
    noValue: 30,
    yesValue: 31,
  },
  {
    order: 2,
    overType: "20 Overs",
    noValue: 55,
    yesValue: 56,
  },
  {
    order: 3,
    overType: "30 Overs",
    noValue: 89,
    yesValue: 90,
  },
  {
    order: 4,
    overType: "40 Overs",
    noValue: 130,
    yesValue: 131,
  },
  {
    order: 5,
    overType: "50 Overs",
    noValue: 170,
    yesValue: 171,
  },
];
