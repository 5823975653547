import React, { useState } from "react";
import { IconContext } from "react-icons";
import "../StyleSheet/sidebarnew.css";
import { CiSquarePlus } from "react-icons/ci";

import { useNavigate } from "react-router-dom";

export default function SidebarItem({ item }) {
  const usenav = useNavigate();
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <div
      className={
        item.childrens || item.subchildren
          ? open
            ? "sidebar-item open"
            : "sidebar-item"
          : "sidebar-item plain"
      }
    >
      <div className="sidebar-title" onClick={handleToggle}>
        <span className="data">
          {item.icon && (
            <IconContext.Provider value={{ style: { paddingLeft: "13px" } }}>
              {React.cloneElement(item.icon, { size: "44px" })}
            </IconContext.Provider>
          )}
          <p onClick={() => usenav(item.path)}> {item.title}</p>
        </span>
        {item.childrens || item.subchildren ? (
          <i
            className="bi-caret-down-fill toggle-btn"
            onClick={handleToggle}
          ></i>
        ) : null}
      </div>
      {item.childrens && open ? (
        <div className="sidebar-content child">
          {item.childrens.map((child, index) => (
            <SidebarItem key={index} item={child} />
          ))}
        </div>
      ) : null}
      {item.subchildren && open ? (
        <div className="sidebar-content subchild">
          {item.subchildren.map((subchild, index) => (
            <SidebarItem key={index} item={subchild} />
          ))}
        </div>
      ) : null}
    </div>
  );
}
