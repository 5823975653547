import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

import "./login.css";

import { Toast } from "react-bootstrap";
import { PuffLoader } from "react-spinners";
import { loginAction } from "../network/store/action/UserResponseAction";
import { useDispatch } from "react-redux";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);

  const [showMessage, setShowMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [requestForm, setRequestForm] = useState({
    userName: "",
    password: "",
  });
  const closeIcon = () => {
    navigate("/ ");
  };

  const handleChanges = (e) => {
    console.log("SDebug > handleChanges", e);
    setRequestForm({ ...requestForm, [e.target.name]: e.target.value });
  };

  const login = async () => {
    localStorage.setItem("authToken", "");
    setIsLoading(true);

    let requestObject = {
      username: requestForm.userName,
      password: requestForm.password,
      role: "1",
    };

    await dispatch(loginAction(requestObject, useDispatch)).then((response) => {
      setIsLoading(false);
      console.log("111111", response);
      if (response.status) {
        localStorage.setItem("authToken", true);
        navigate("/");
      } else {
        setShowMessage("Invalid username or Password");
        setShow(true);
      }
    });
  };

  return (
    <>
      {isLoading && (
        <div
          style={{
            position: "fixed",
            backgroundColor: "rgba(0,0,0,0.5)",
            width: "100%",
            height: "100%",
            zIndex: "999",
            overflow: "scroll",
          }}
        >
          <div className="loaderParentDiv">
            <div className="loaderDivCenter">
              <PuffLoader color="#7d04a9" loading={isLoading} size={80} />
            </div>
          </div>
        </div>
      )}{" "}
      <div className="container-fluid login-bg">
        <div className="row">
          <div className="login card-1">
            <div>
              <h3 className="text-centers" style={{ color: "#fff" }}>
                <img src="" alt="" width="100%" />
              </h3>
            </div>
            <div>
              <div style={{ position: "absolute", left: 780, top: 180 }}>
                <CloseIcon />
              </div>
              <h5 style={{ textAlign: "center" }}>LogIn</h5>
              <div className="close-icon">
                <CloseIcon onClick={closeIcon} />
              </div>
              <div>
                <div className="form-group">
                  <label htmlFor="form2Example11">UserId</label>
                  <input
                    type="email"
                    name="userName"
                    value={requestForm.userName}
                    onChange={(e) => handleChanges(e)}
                    id="form2Example11"
                    className="form-control"
                    placeholder="Enter email/Mobile number"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="form2Example22">Password</label>
                  <input
                    type="password"
                    name="password"
                    value={requestForm.password}
                    onChange={(e) => handleChanges(e)}
                    id="form2Example22"
                    className="form-control"
                    placeholder="Enter Password"
                  />
                </div>
              </div>
              <div>
                <button
                  className="btn btn-primary w-100 text-center"
                  onClick={(e) => login(e)}
                >
                  LOGIN
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 10,
          }}
        >
          <Toast
            onClose={() => setShow(false)}
            show={show}
            delay={3000}
            autohide
          >
            <Toast.Header className="table" closeButton={false}>
              <strong className="mr-auto">Alert !!</strong>
            </Toast.Header>
            <Toast.Body className="table">{showMessage}</Toast.Body>
          </Toast>
        </div>
      </div>
    </>
  );
};

export default Login;
