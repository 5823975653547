import React from "react";
import BettingTemp from "../Components/BettingTemp";
import { tennisMatches } from "../LiveData/gamesData";

const Tennis = () => {
  return <div>
      <BettingTemp value={tennisMatches} />
  </div>;
};

export default Tennis;
