import React from "react";
import { useParams } from "react-router-dom";
import "../StyleSheet/matchBet.css";
import { cricketMatches, tennisMatches } from "../LiveData/gamesData";

const MatchesDetalils = () => {
  const { id, game } = useParams();
  console.log(id, "::::", game);

  let matchInfo;

  if (game == "cricket") {
    matchInfo = cricketMatches[0].allMatches.find(
      (eachMatch) => eachMatch.id == id
    );
  } else if (game == "tennis") {
    console.log(";;;;;;;;;;;;;", tennisMatches[0]);
    matchInfo = tennisMatches[0].allMatches.find(
      (eachMatch) => eachMatch.id == id
    );
  }

  console.log(":::::", matchInfo);

  return (
    <div>
      <div className="cricket-bet-data">
        <div className="live-bet-container">
          <div className="match-odd">
            <p>match odds</p>
            <p>min:100</p>
          </div>
          <div className="team-bet-container">
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>Back</th>
                  <th>Lay</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{matchInfo.team1.name}</td>
                  <td>{matchInfo.team1.back}</td>
                  <td>{matchInfo.team1.lay}</td>
                </tr>
                <tr>
                  <td>{matchInfo.team2.name}</td>
                  <td>{matchInfo.team2.back}</td>
                  <td>{matchInfo.team2.lay}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="fancy-bet">
        <h2>Fancy</h2>

        {matchInfo.fancy && (
          <table>
            <thead>
              <tr>
                <th></th>
                <th>No</th>
                <th>Yes</th>
              </tr>
            </thead>
            <tbody>
              {matchInfo.fancy.map((over, id) => {
                return (
                  <tr key={id}>
                    <td>{over.overs}</td>
                    <td> {over.no}</td>
                    <td> {over.yes}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default MatchesDetalils;
