import React, { useState } from "react";

import "../StyleSheet/imagerSlider.css"

const images = [
  "https://as1.ftcdn.net/v2/jpg/02/73/10/00/1000_F_273100058_PpDtFDEAZ40xZCKs1OJqX1m7CIvueOdt.jpg",
  "https://as2.ftcdn.net/v2/jpg/05/20/75/15/1000_F_520751597_7SNeNpPZMqJfqpC2dRq2UuzdgmNNWHD5.jpg",
  "https://img.freepik.com/premium-photo/soccer-ball-net-goal-soccer-concept_355067-418.jpg?w=826",
  "https://as1.ftcdn.net/v2/jpg/03/66/32/30/1000_F_366323061_dVSeQ4PE899OvQB17cyAlUSBV2994GPY.jpg",
  "https://img.freepik.com/free-photo/professional-boxer-training-isolated-black-studio-background-mixed-light_155003-17915.jpg?w=740&t=st=1706530293~exp=1706530893~hmac=397b1a9142956c2a8c43af765b2bee440c318bd810f4ca77f2c277f5ee96bc8d",
];
const ImageSlider = () => {
  const [currentId, setCurrentId] = useState(0);

  
  return (
    <div className="img-xont">
      <img src={images[currentId]} alt="baneerData" />
      <div className="slider-btn">
        {images.map((val, id) => {
          const dotClass = id === currentId ? "selected-dot" : "btn-cont-primary";
          return (
            <div
              className={dotClass}
              key={id}
              onClick={() => setCurrentId(id)}
            >
              ●
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ImageSlider;
