import React from "react";
import { NavLink } from "react-router-dom";
import "../StyleSheet/navigationBar.css";
const NavBar = () => {
  return (
    <div>
      <div className="navigation-sec">
        <NavLink to="/">Home</NavLink>
        <NavLink to="/inplay" className="special-link">Inplay</NavLink>
        <NavLink to="/cricket" className="special-link">Cricket</NavLink>
        <NavLink to="/soccer">soccer</NavLink>
        <NavLink to="/tennis">Tennis</NavLink>
        {/* <NavLink to="/horseracing">Horse </NavLink>
        <NavLink to="/football">FootBall</NavLink>
        <NavLink to="/grayhandracing">GrayHound </NavLink>
        <NavLink to="/casino">Casino</NavLink> */}
      </div>
      <div className="marquiee">
        <marquee>
          All Sports, Unlimited Live & Virtual Casino, Unlimited Slot Games On
          Very Popular, Simple & Fastest Exchange.{" "}
        </marquee>
      </div>
    </div>
  );
};

export default NavBar;
