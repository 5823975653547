import React from "react";
import { Routes, Route } from "react-router-dom";
import "../src/App.css";
import NavBar from "./Components/NavBar";
import Header from "./Components/Header";
import Home from "./NavigationComponents/Home";
import Rules from "./statementsComponents/Rules";
import Inplay from "./NavigationComponents/Inplay";
import Tennis from "./NavigationComponents/Tennis";
import SideBar from "./Components/SideBar";
import Cricket from "./NavigationComponents/Cricket";
import Login from "./auth/login";
import BetHistory from "./statementsComponents/BetHistory";
import MultiMarket from "./NavigationComponents/MultiMarket";
import NopageFound from "./NavigationComponents/NopageFound";
import CgBetHistory from "./statementsComponents/CgBetHistory";
import UnsettledBets from "./statementsComponents/UnsettledBets";
import PasswordChange from "./statementsComponents/PasswordChange";
import ProfitLossReport from "./statementsComponents/ProfitLossReport";
import AccountSatatement from "./statementsComponents/AccountSatatement";
import CgAccountStatement from "./statementsComponents/CgAccountStatement";
import Soccer from "./NavigationComponents/Soccer";
import MatchesDetalils from "./Components/MatchesDetalils";
import CgUnsettledBets from "./statementsComponents/CgUnsettledBets";

const App = () => {
  return (
    <div className="app-container">
      <Header />
      <NavBar />
      <div className="app-data">
        <div className="side-Bar">
          <SideBar />
        </div>
        <div className="routing-data">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/matchDetails/:game/:id"
              element={<MatchesDetalils />}
            />
            <Route path="/inplay" element={<Inplay />} />
            <Route path="/cricket" element={<Cricket />} />
            <Route path="/tennis" element={<Tennis />} />
            <Route path="/soccer" element={<Soccer />} />
            <Route path="/multmarket" element={<MultiMarket />} />
            <Route path="/accountStatement" element={<AccountSatatement />} />
            <Route path="/profit&loss" element={<ProfitLossReport />} />
            <Route path="/betHistory" element={<BetHistory />} />
            <Route path="/cgbetHistory" element={<CgBetHistory />} />
            <Route path="/unSettledBet" element={<UnsettledBets />} />
            <Route path="/CgunSettledBet" element={<CgUnsettledBets />} />
            <Route path="/passwordChange" element={<PasswordChange />} />
            <Route path="/cgSta" element={<CgAccountStatement />} />
            <Route path="/rules" element={<Rules />} />
            <Route path="*" element={<NopageFound />} />
          </Routes>
        </div>
      </div>
      <Routes>
        <Route path="/login" element={<Login />} />
      </Routes>
    </div>
  );
};

export default App;
