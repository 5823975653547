import React, { useState } from "react";
import "../StyleSheet/cgaccountstatement.css";

const CgAccountStatement = () => {
  const [selectedReportType, setSelectedReportType] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");

  const handleReportTypeChange = (event) => {
    setSelectedReportType(event.target.value);
  };

  const handleStartDateChange = (event) => {
    setSelectedStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setSelectedEndDate(event.target.value);
  };

  const reSetDates = () => {
    setSelectedStartDate("");
    setSelectedEndDate("");
    alert("I am working..........");
  };

  return (
    <div className="cgaccount-statement-container">
      <h2>club games account Statement</h2>
      <div className="cgreport-options-container">
        <div className="report-type-dropdown">
          <select
            id="dropdown"
            value={selectedReportType}
            onChange={handleReportTypeChange}
          >
            <option value="">ALL..</option>
            <option value="BALANCE REPORT">BALANCE REPORT</option>
            <option value="GAME REPORT">GAME REPORT</option>
          </select>
        </div>
        <div>
          <input
            type="date"
            id="start"
            name="start-date"
            onChange={handleStartDateChange}
            value={selectedStartDate}
            min="2018-01-01"
            max="2025-12-31"
          />
        </div>
        <div>
          <input
            type="date"
            id="end"
            name="end-date"
            onChange={handleEndDateChange}
            value={selectedEndDate}
            min="2018-01-01"
            max="2025-12-31"
          />
        </div>
        <div className="btn-act">
          <button>GO</button>
          <button onClick={reSetDates}>Reset</button>
        </div>
      </div>
    </div>
  );
};

export default CgAccountStatement;
