import * as React from "react";
import usePagination from "@mui/material/usePagination";
import { styled } from "@mui/material/styles";

const List = styled("ul")({
  listStyle: "none",
  padding: 0,
  margin: 0,
  display: "flex",
  justifyContent: "flex-start",
});

const CustomButton = styled("button")({
  cursor: "pointer",
  borderRadius: "4px",
  padding: "8px 12px",
  margin: "0 4px",
  border: "1px solid #ccc",
  background: "#36bdd3",
  color: "black",
  fontWeight: "normal",
  textTransform:"capitalize",
  background: "linear-gradient(to top, #1a4360, #1aa3b1)",
});

const CustomPagination = ({ count, page, onChange, initialPageNumbers }) => {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 768);
  const { items } = usePagination({
    count,
    page,
    onChange,
  });
  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const displayedItems = isMobile
    ? items.filter(({ type }) => type === "previous" || type === "next")
    : items.slice(0, initialPageNumbers);

  return (
    <nav>
      <List style={{ display: "flex", justifyContent: "flex-end" }}>
        {displayedItems.map(({ page, type, selected, ...item }, index) => {
          let children = null;

          if (type === "start-ellipsis" || type === "end-ellipsis") {
            children = "…";
          } else if (type === "page") {
            children = (
              <CustomButton
                type="button"
                style={{
                  fontWeight: selected ? "bold" : "normal",
                  backgroundColor: selected ? "#1aa3b1" : "#3b8aa3",
                  color: selected ? "white" : "black",
                }}
                {...item}
              >
                {page}
              </CustomButton>
            );
          } else {
            const isFirstPage = page === 0;
            const shouldDisableColor = type === "previous" && isFirstPage;

            children = (
              <CustomButton
                type="button"
                style={{
                  cursor: shouldDisableColor ? "default" : "pointer",
                  borderRadius: "4px",
                  padding: "8px 12px",
                  margin: "0 4px",
                  border: "1px solid #ccc",
                  background: shouldDisableColor ? "transparent" : "#36bdd3",
                  color: shouldDisableColor ? "black" : "black",
                  fontWeight: "normal",
                }}
                {...item}
              >
                {type}
              </CustomButton>
            );
          }

          return <li key={index}>{children}</li>;
        })}
      </List>
    </nav>
  );
};

export default CustomPagination;
