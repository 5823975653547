export const USER_API_PATH = {
  LOGIN_PATH: "Auth/login",
  REGISTRATION_PATH: "Auth/addUser",
  UPDATE_USER: "User/update",
  UPDATE_USER_PWD: "User/updatePassword",
  USER_INFO: "User/info?id=",
  LIST_PATH: "User/listUsers",
};
export const WALLET_API_PATH = {
  WALLET_INFO:"UserWallet/walletBalence"

};
