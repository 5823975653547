import { postRequest } from "./config/PostRequest";
import {  WALLET_API_PATH } from "../config/apiPaths";
import { getRequest } from "./config/GetRequest";









export const walletInfo = () => async () => {
  try {
    let result = await getRequest(
      `${WALLET_API_PATH.WALLET_INFO}?userId=${localStorage.getItem("userId")}`,
      null,
      false
    );
    return result;
  } catch (error) {
    console.log(error);
    // TODO Handle Error
  }
};


