import SidebarItem from "../Components/SidebarItem";
import { sidebarData } from "../LiveData/sidebarjson";
import "../StyleSheet/sideBar.css";

export default function Sidebar() {
  return (
    <div className="sidebar-cont">
      <h6>POPULAR</h6>
      <div className="sidebar">
        {sidebarData.map((item, index) => (
          <SidebarItem key={index} item={item} />
        ))}
      </div>
    </div>
  );
}
