import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import CloseIcon from "@mui/icons-material/Close";
import "../StyleSheet/header.css";

import { useDispatch } from "react-redux";

import { walletInfoAction } from "../network/store/action/WalletResponseAction";
const useLogIn = localStorage.getItem("authToken");

const userIsLogin=localStorage.getItem("authToken")
const Header = () => {
  const usenav = useNavigate();

  const dispatch = useDispatch();

  const [isuserDetails, setIsUserDetails] = useState(false);
  const [walletBalance, setWalletBalance] = useState(0);

  const [isUserIn,setIsUserIn]=useState(userIsLogin)
  console.log(isUserIn);
useEffect(()=>{
  setIsUserIn(userIsLogin)


},[isUserIn])
  const toggleUserDetails = () => {
    setIsUserDetails(!isuserDetails);
  };
  const login = () => {
    usenav("/");
  };
  const logOut = () => {

      setIsUserDetails(false);
      localStorage.setItem("authToken", false)
      usenav("/");
    

    setIsUserDetails(false);
    localStorage.getItem("authToken", "");
    usenav("/");
  };

  const navigateAndClose = (path) => {
    usenav(path);
    setIsUserDetails(false);
  };
  useEffect(() => {
    walletBalanceCall();
  }, []);
  useEffect(() => {
    console.log("hii", useLogIn);
  }, [useLogIn]);

  const walletBalanceCall = async () => {
    await dispatch(walletInfoAction(dispatch)).then((reponse) => {
      if (reponse.result) {
        setWalletBalance(reponse.result);
      } else {
        setWalletBalance(0);
      }
    });
  };

  return (
    <div>
      <div className="main-header">
        <div className="logo">
          <p onClick={() => usenav("/")}>
            <span>Legends</span>
            <span>Exchange</span>
          </p>
        </div>

       

        {isUserIn  ? (
          <div className="main-amount">
            <button onClick={toggleUserDetails} className="btn1">
              Coins {walletBalance}
            </button>
            <button onClick={toggleUserDetails} className="btn2">
              Exp: {walletBalance}
            </button>
          </div>
        ) : (
          <button className="login-button" onClick={login}>
            LogIn
          </button>
        )}
      </div>

      <div className="user-need-data">
        {isuserDetails && (
          <div>
            <div className="close-menu">
              <CloseIcon
                onClick={toggleUserDetails}
                className="closed-toogle"
              />
            </div>
            <div className="actn-holder-details">
              <h3 style={{ color: "white" }}>
                {localStorage.getItem("userName")}
              </h3>
              <h3 style={{ color: "white" }}>{walletBalance}</h3>
            </div>
            <div className="btn-grp">
              <button>Exposure</button>
              <button>P&L</button>
            </div>
            <div className="btn-grp">
              <button>CG Exposure</button>
              <button>CG P&L</button>
            </div>
            <div className="user-details-settings">
              <button onClick={() => navigateAndClose("/accountStatement")}>
                Account Statement
              </button>
              <button onClick={() => navigateAndClose("/cgSta")}>
                CG Account Statement
              </button>
              <button onClick={() => navigateAndClose("/profit&loss")}>
                Profit Loss Report
              </button>
              <button onClick={() => navigateAndClose("/betHistory")}>
                Bet History
              </button>
              <button onClick={() => navigateAndClose("/cgbetHistory")}>
                CG Bet History
              </button>
              <button onClick={() => navigateAndClose("/unSettledBet")}>
                Unsettled Bet
              </button>
              <button onClick={() => navigateAndClose("/CgunSettledBet")}>
                CG Unsettled Bets
              </button>
              <button onClick={() => navigateAndClose("/rules")}>Rules</button>
              <button onClick={() => navigateAndClose("/passwordChange")}>
                Change Password
              </button>
              <button onClick={logOut}>Logout</button>
            </div>
          </div>
        )}
    </div>
    </div>
   
   
  );
};

export default Header;
