import React from "react";
import BettingTemp from "../Components/BettingTemp";
import { cricketMatches, tennisMatches } from "../LiveData/gamesData";
import ImageSlider from "../Components/ImageSlider";

const Home = () => {
  return (
    <div>
      <ImageSlider/>
      <BettingTemp value={cricketMatches} />
      <BettingTemp value={tennisMatches} />
    </div>
  );
};

export default Home;
