import React, { useState } from "react";
import "../StyleSheet/profitLoss.css";

const ProfitLossReport = () => {
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");
  const [profitData, setProfitData] = useState(false);

  const handleStartDateChange = (event) => {
    setSelectedStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setSelectedEndDate(event.target.value);
  };
  const getProfitData = () => {
    setProfitData(true);
  };

  const reSetDates = () => {
    setSelectedStartDate("");
    setSelectedEndDate("");
    alert("I am working..........");
  };

  return (
    <>
      <div className="profit-statement-container">
        <h2>Profit and Loss</h2>
        <div className="profit-options-container">
          <div>
            <input
              type="date"
              id="start"
              name="start-date"
              onChange={handleStartDateChange}
              value={selectedStartDate}
              min="2018-01-01"
              max="2025-12-31"
            />
          </div>
          <div>
            <input
              type="date"
              id="end"
              name="end-date"
              onChange={handleEndDateChange}
              value={selectedEndDate}
              min="2018-01-01"
              max="2025-12-31"
            />
          </div>
          <div className="btn-act">
            <button onClick={getProfitData}>GO</button>
            <button onClick={reSetDates}>Reset</button>
          </div>
        </div>
      </div>
      {profitData && (
        <div className="profit-loss-table">
          <table>
            <thead>
              <tr>
                <th>SPORT</th>
                <th>MARKET NAME</th>
                <th>AMOUNT</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>1</td>
                <td>1</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default ProfitLossReport;
