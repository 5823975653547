import {
    walletInfo
  } from "../../service/WalletService";
  

  export const walletInfoAction = () => async (dispatch) => {
    const response = await dispatch(walletInfo());
    console.log(response);
    if (
      (response &&
        Object.keys(response).length &&
        response?.data?.status == 201) ||
      200
    ) {
      console.log(response);
      return Promise.resolve(response.data);
    } else {
      return Promise.resolve(null);
    }
  };
  
