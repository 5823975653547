import React from "react";
import "../StyleSheet/bettingtemp.css";
import { useNavigate } from "react-router-dom";
import { TbPinnedFilled } from "react-icons/tb";
const BettingTemp = ({ value }) => {
  const usenav = useNavigate();
  return (
    <div className="match-list-container">
      <p>{value[0].game}</p>
      <div className="match-data-stakes">
        <table>
          <tbody>
            {value[0].allMatches.map((match, id) => {
              return (
                <tr>
                  <td
                    key={id}
                    onClick={() =>
                      usenav(`/matchDetails/${value[0].game}/${id}`)
                    }
                  >
                    <span className="match-time"> {match.time}</span>
                    <span className="team1">{match.team1.name}</span>

                    <span style={{ color: "red", marginLeft: "5px" }}>vs</span>
                    <span className="team2-name">{match.team2.name}</span>
                    <span style={{ marginLeft: "8px", color: "#7bc3df" }}>
                      Live Now
                    </span>
                  </td>
                  <td>{match.team1.back}</td>
                  <td>{match.team1.lay}</td>
                  <td>{match.team2.back}</td>
                  <td>{match.team2.lay}</td>
                  <td>{match.team2.back}</td>
                  <td>{match.team2.lay}</td>
                  <td className="pinned-icon">
                    <TbPinnedFilled />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BettingTemp;
