import React, { useState } from "react";
import "../StyleSheet/profitLoss.css";

const CgBetHistory = () => {
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");

  const handleStartDateChange = (event) => {
    setSelectedStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setSelectedEndDate(event.target.value);
  };

  const reSetDates = () => {
    setSelectedStartDate("");
    setSelectedEndDate("");
    alert("I am working..........");
  };

  return (
    <div className="profit-statement-container">
      <h2>Club Game history Statement</h2>
      <div className="profit-options-container">
        <div>
          <input
            type="date"
            id="start"
            name="start-date"
            onChange={handleStartDateChange}
            value={selectedStartDate}
            min="2018-01-01"
            max="2025-12-31"
          />
        </div>
        <div>
          <input
            type="date"
            id="end"
            name="end-date"
            onChange={handleEndDateChange}
            value={selectedEndDate}
            min="2018-01-01"
            max="2025-12-31"
          />
        </div>
        <div className="btn-act">
          <button>GO</button>
          <button onClick={reSetDates}>Reset</button>
        </div>
      </div>
    </div>
  );
};

export default CgBetHistory;
