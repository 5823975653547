const userAccountDetails = [
  {
    date: "10-11-2023",
    credit: 1000,
    debit: 300,
    balance: 700,
    description: "closing",
  },
  {
    date: "11-11-2023",
    credit: 800,
    debit: 200,
    balance: 1300,
    description: "purchase",
  },
  {
    date: "12-11-2023",
    credit: 500,
    debit: 100,
    balance: 1700,
    description: "withdrawal",
  },
  {
    date: "13-11-2023",
    credit: 1200,
    debit: 400,
    balance: 2500,
    description: "deposit",
  },
  {
    date: "14-11-2023",
    credit: 300,
    debit: 50,
    balance: 2750,
    description: "expense",
  },
  {
    date: "15-11-2023",
    credit: 600,
    debit: 200,
    balance: 3150,
    description: "purchase",
  },
  {
    date: "16-11-2023",
    credit: 700,
    debit: 100,
    balance: 3250,
    description: "payment",
  },
  {
    date: "17-11-2023",
    credit: 200,
    debit: 50,
    balance: 3300,
    description: "refund",
  },
  {
    date: "18-11-2023",
    credit: 1000,
    debit: 300,
    balance: 4000,
    description: "deposit",
  },
  {
    date: "19-11-2023",
    credit: 400,
    debit: 150,
    balance: 4250,
    description: "purchase",
  },
  {
    date: "15-11-2023",
    credit: 600,
    debit: 200,
    balance: 3150,
    description: "purchase",
  },
  {
    date: "16-11-2023",
    credit: 700,
    debit: 100,
    balance: 3250,
    description: "payment",
  },
  {
    date: "17-11-2023",
    credit: 200,
    debit: 50,
    balance: 3300,
    description: "refund",
  },
  {
    date: "18-11-2023",
    credit: 1000,
    debit: 300,
    balance: 4000,
    description: "deposit",
  },
  {
    date: "19-11-2023",
    credit: 400,
    debit: 150,
    balance: 4250,
    description: "purchase",
  },
  {
    date: "10-11-2023",
    credit: 1000,
    debit: 300,
    balance: 700,
    description: "closing",
  },
  {
    date: "11-11-2023",
    credit: 800,
    debit: 200,
    balance: 1300,
    description: "purchase",
  },
  {
    date: "12-11-2023",
    credit: 500,
    debit: 100,
    balance: 1700,
    description: "withdrawal",
  },
  {
    date: "13-11-2023",
    credit: 1200,
    debit: 400,
    balance: 2500,
    description: "deposit",
  },
  {
    date: "14-11-2023",
    credit: 300,
    debit: 50,
    balance: 2750,
    description: "expense",
  },
  {
    date: "15-10-2023",
    credit: 900,
    debit: 200,
    balance: 3150,
    description: "purchase",
  },
  {
    date: "17-11-2023",
    credit: 9700,
    debit: 100,
    balance: 3250,
    description: "payment",
  },
  {
    date: "17-11-2023",
    credit: 200,
    debit: 5990,
    balance: 3300,
    description: "refund",
  },
  {
    date: "18-11-2023",
    credit: 1000,
    debit: 300,
    balance: 4000,
    description: "deposit",
  },
  {
    date: "19-11-2023",
    credit: 400,
    debit: 150,
    balance: 4250,
    description: "purchase",
  },
  {
    date: "15-11-2023",
    credit: 9900,
    debit: 200,
    balance: 322150,
    description: "purchase",
  },
  {
    date: "16-11-2023",
    credit: 700,
    debit: 100,
    balance: 3250,
    description: "payment",
  },
  {
    date: "17-11-2023",
    credit: 200,
    debit: 50,
    balance: 3300,
    description: "refund",
  },
  {
    date: "18-11-2023",
    credit: 1000,
    debit: 300,
    balance: 4000,
    description: "deposit",
  },
  {
    date: "19-11-2023",
    credit: 400,
    debit: 150,
    balance: 4250,
    description: "purchase",
  },
  {
    date: "10-11-2023",
    credit: 1000,
    debit: 300,
    balance: 700,
    description: "closing",
  },
  {
    date: "11-11-2023",
    credit: 800,
    debit: 200,
    balance: 1300,
    description: "purchase",
  },
  {
    date: "12-11-2023",
    credit: 500,
    debit: 100,
    balance: 1700,
    description: "withdrawal",
  },
  {
    date: "13-11-2023",
    credit: 1200,
    debit: 400,
    balance: 2500,
    description: "deposit",
  },
  {
    date: "14-11-2023",
    credit: 300,
    debit: 50,
    balance: 2750,
    description: "expense",
  },
  {
    date: "15-11-2023",
    credit: 222600,
    debit: 2200,
    balance: 3150,
    description: "purchase",
  },
  {
    date: "16-11-2023",
    credit: 700,
    debit: 100,
    balance: 3250,
    description: "payment",
  },
  {
    date: "17-11-2023",
    credit: 200,
    debit: 50,
    balance: 3300,
    description: "refund",
  },
  {
    date: "18-11-2023",
    credit: 1000,
    debit: 300,
    balance: 4000,
    description: "deposit",
  },
  {
    date: "19-11-2023",
    credit: 400,
    debit: 150,
    balance: 4250,
    description: "purchase",
  },
  {
    date: "15-11-2023",
    credit: 1600,
    debit: 200,
    balance: 3150,
    description: "purchase",
  },
  {
    date: "16-11-2023",
    credit: 700,
    debit: 100,
    balance: 3250,
    description: "payment",
  },
  {
    date: "17-11-2023",
    credit: 200,
    debit: 50,
    balance: 3300,
    description: "refund",
  },
  {
    date: "18-11-2023",
    credit: 1000,
    debit: 300,
    balance: 4000,
    description: "deposit",
  },
  {
    date: "19-11-2023",
    credit: 400,
    debit: 150,
    balance: 4250,
    description: "purchase",
  },
];
export default userAccountDetails;
