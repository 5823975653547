import React, { useState } from "react";
import "../StyleSheet/betHistory.css";
import Stack from "@mui/material/Stack";
import CustomPagination from "../AccountStatemets/AccountPagination/CustomPagination.jsx";
import userAccountDetails from "../AccountStatemets/accountstatement.js";

const AccountStatement = () => {
  const [selectedReportType, setSelectedReportType] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  const indexOfLastRecord = currentPage * itemsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - itemsPerPage;
  const currentRecords = userAccountDetails.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(value);
    setCurrentPage(1);
  };

  const handleReportTypeChange = (event) => {
    setSelectedReportType(event.target.value);
  };

  const handleStartDateChange = (event) => {
    setSelectedStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setSelectedEndDate(event.target.value);
  };

  const reSetDates = () => {
    setSelectedStartDate("");
    setSelectedEndDate("");
    alert("I am working..........");
  };

  return (
    <div className="bethistory-statement-container">
      <h2>Bet History</h2>
      <div className="bethistory-options-container">
        <div className="report-type-dropdown">
          <select
            id="dropdown"
            value={selectedReportType}
            onChange={handleReportTypeChange}
          >
            <option value="">sports..</option>
            <option value="Casino">BALANCE REPORT</option>
            <option value="BINARY">GAME REPORT</option>
            <option value="SPORT BOOK">SETTLEMENT REPORT</option>
          </select>
        </div>
        <div>
          <input
            type="date"
            id="start"
            name="start-date"
            onChange={handleStartDateChange}
            value={selectedStartDate}
            min="2018-01-01"
            max="2025-12-31"
          />
        </div>
        <div>
          <input
            type="date"
            id="end"
            name="end-date"
            onChange={handleEndDateChange}
            value={selectedEndDate}
            min="2018-01-01"
            max="2025-12-31"
          />
        </div>
        <div className="btn-act">
          <button>GO</button>
          <button onClick={reSetDates}>Reset</button>
        </div>
      </div>
      <div className="profit-search">
        <input type="text" placeholder="search" />
      </div>
      <div className="profit-loss-table">
        <table>
          <thead>
            <tr>
              <th>DATE</th>
              <th>Competition</th>
              <th>Event</th>
              <th>Market</th>
              <th>Runner</th>
              <th>Side</th>
              <th>Line</th>
              <th>Rate</th>
              <th>Amount</th>
              <th>Profit/Loss </th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>10-11-2022</td>
              <td>cricket</td> <td>sport</td> <td>ipl</td> <td>Csk</td>{" "}
              <td>10000</td> <td>80</td> <td>4.4</td>
              <td>40000</td>
              <td>40000</td>
              <td>loss</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <Stack spacing={2}>
          <CustomPagination
            style={{ left: "50px" }}
            className="pagination-number"
            count={Math.ceil(userAccountDetails.length / itemsPerPage)}
            page={currentPage}
            onChange={(_e, page) => handlePageChange(page)}
            variant="outlined"
            shape="rounded"
          />
        </Stack>
      </div>
    </div>
  );
};

export default AccountStatement;
