import React, { useState } from "react";
import "../StyleSheet/passwordManger.css";

const PasswordChange = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleOldPaasword = (event) => {
    setOldPassword(event.target.value);
  };
  const handlenewPaasword = (event) => {
    setNewPassword(event.target.value);
  };
  const handleRePaasword = (event) => {
    setConfirmPassword(event.target.value);
  };

  return (
<div className="password-change">
<h2>Password Change</h2>



    <div className="password-form-data">
      
      <div className="frm-cntrl">
        <label htmlFor="oldPassword">Old Password :</label>
        <input
          type="password"
          name="oldpassword"
          value={oldPassword}
          onChange={handleOldPaasword}
        />
        <label htmlFor="newPassword">New Password :</label>
        <input
          type="password"
          name="newpassword"
          value={newPassword}
          onChange={handlenewPaasword}
        />
        <label htmlFor="newPassword">Confirm Password :</label>
        <input
          type="password"
          name="confirmpassword"
          value={confirmPassword}
          onChange={handleRePaasword}
        />
        <div>
          <button onClick={() => alert("I am Working...")}>Update</button>
        </div>
      </div>
    </div>
    </div>
  );
};
export default PasswordChange;
