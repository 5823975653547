import React, { useState } from "react";
import { FaMinus } from "react-icons/fa";
import "../alllivegamescs/cricketBallBet.css";
import { teams,overData } from "../LiveData/gamesData";

const BallBet = () => {
  const [team1, setTeam1] = useState(null);
  const [team2, setTeam2] = useState(null);
  const [teamBet, setTeamBet] = useState(0);
  const [betType, setBetType] = useState("");
  const [teamName, setTeamName] = useState("");
  const [betamount, setBetAmount] = useState(false);
  const [betInputValue, setBetInputValue] = useState(0);
  const betValue = [100, 200, 300, 600, 900, 1000];

  const handleBet = (val, name, type) => {
    setBetAmount(true);
    setTeamName(name);
    setTeamBet(val);
    setBetType(type);
  };

  const handleButtonClick = (amount) => {
    setBetInputValue(amount);
  };
  const betIncrease = () => {
    const newBetValue = betInputValue + betInputValue;
    setBetInputValue(newBetValue);
  };
  const betDecrease = () => {
    const newBetValue = betInputValue / 2;
    setBetInputValue(newBetValue);
  };
  const handleBetClose = () => {
    setBetInputValue(0);
    setBetAmount(false);
    if (teamName === "Bangaladesh" && betType === "Back") {
      let result = (teamBet - 1) * betInputValue;
      setTeam1((prevAmount) => prevAmount + result);
      setTeam2((prevAmount) => prevAmount - betInputValue);
      setBetInputValue(0);
      setBetAmount(false);
    }
  };

  const betPlaced = () => {
    if (betInputValue < 100) {
      alert("please enter minimum");
    } else if (betInputValue > 10000) {
      alert("you have reached maximum bet value");
    } else {
      if (teamName === "Bangaladesh" && betType === "Back") {
        let result = (teamBet - 1) * betInputValue;
        setTeam1((prevAmount) => prevAmount + result);
        setTeam2((prevAmount) => prevAmount - betInputValue);
        setBetInputValue(0);
        setBetAmount(false);
      } else if (teamName === "Srilanka" && betType === "Back") {
        let result = (teamBet - 1) * betInputValue;
        setTeam2((prevAmount) => prevAmount + result);
        setTeam1((prevAmount) => prevAmount - betInputValue);
        setBetInputValue(0);
        setBetAmount(false);
      } else if (teamName === "Bangaladesh" && betType === "Lay") {
        let result = (teamBet - 1) * betInputValue;
        setTeam1((prevAmount) => prevAmount - result);
        setTeam2((prevAmount) => prevAmount + betInputValue);
        setBetInputValue(0);
        setBetAmount(false);
      } else if (teamName === "Srilanka" && betType === "Lay") {
        let result = (teamBet - 1) * betInputValue;
        setTeam2((prevAmount) => prevAmount - result);
        setTeam1((prevAmount) => prevAmount + betInputValue);
        setBetInputValue(0);
        setBetAmount(false);
      }
    }
  };

  return (
    <div className="cricket-bet-data">
      <div className="live-bet-container">
        <div className="match-odd">
          <p>match odds</p>
          <p>min:100</p>
        </div>
        <div className="team-bet-container">
          <table>
            <thead>
              <tr>
                <th></th>
                <th>Back</th>
                <th>Lay</th>
              </tr>
            </thead>
            <tbody>
              {teams.map((team, id) => {
                return (
                  <tr key={id}>
                    <td>
                      {team.name}
                      <span
                        style={{
                        float:"right",
                         color:
                            team.name === "Bangaladesh"
                              ? team1 >= 0
                                ? "green"
                                : "red"
                              : team2 >= 0
                              ? "green"
                              : "red",
                        }}
                      >
                        {team.name === "Bangaladesh" ? team1 : team2}
                      </span>
                    </td>
                    <td onClick={() => handleBet(team.back, team.name, "Back")}>
                      {team.back}
                    </td>
                    <td onClick={() => handleBet(team.lay, team.name, "Lay")}>
                      {team.lay}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {betamount && (
            <>
              <div className="bet-placed-container">
                <p>Selected team</p>
                <div className="bet-amount-fields">
                  <button className="decrement-btn">-</button>
                  <input type="number" value={teamBet} />
                  <button className="increment-btn">+</button>
                </div>
                <div className="bet-amount-fields">
                  <button className="decrement-btn" onClick={betDecrease}>
                    -
                  </button>
                  <input
                    type="number"
                    value={betInputValue}
                    onChange={(e) => setBetInputValue(e.target.value)}
                  />

                  <button className="increment-btn" onClick={betIncrease}>
                    +
                  </button>
                </div>
              </div>
              <div className="bet-amount">
                {betValue.map((amount, id) => {
                  return (
                    <>
                      <button
                        key={id}
                        onClick={() => handleButtonClick(amount)}
                      >
                        {amount}
                      </button>
                    </>
                  );
                })}

                <button className="placed" onClick={betPlaced}>
                  place Bet
                </button>
                <button className="close" onClick={handleBetClose}>
                  cancel
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="fancy-bets">
        <p>Fancy</p>
       <div  className="all-fancy">
       <div>
          <button>ALL</button>
          <button>OVERS</button>
          <button>BATSMAN</button>
        </div>
        <div>
          <button>
            <FaMinus/>
          </button>
        </div>
       </div>
        <div className="fancy-bet-container">
          <table>
            <thead>
              <tr>
                <th></th>
                <th>NO</th>
                <th>YES</th>
              </tr>
            </thead>
            <tbody>
              {overData.map((fancy, id) => {
                return (
                  <tr key={id}>
                    <td>{fancy.overType}</td>
                    <td>{fancy.noValue}</td>
                    <td>{fancy.yesValue}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default BallBet;
