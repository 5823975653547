import { IoIosFootball } from "react-icons/io";
import { MdOutlineSportsCricket } from "react-icons/md";
import { FaPlayCircle } from "react-icons/fa";

import { MdSportsKabaddi } from "react-icons/md";
export const sidebarData = [
  { title: "INPLAY", path: "/inplay", icon: <FaPlayCircle /> },
  { title: "SOCCER", path: "/soccer", icon: <MdSportsKabaddi /> },
  {
    title: "CRICKET",
    path: "/cricket",
    icon: <MdOutlineSportsCricket />,
    childrens: [
      {
        title: "IPL",
        path: "/cricket/teams",
        subchildren: [
          {
            title: "CSK Vs MI",
            path: "/cricket/teams/national",
          },
          {
            title: "KKR Vs SRH",
            path: "/cricket/teams/club",
          },
        ],
      },
    ],
  },
  {
    title: "FOOTBALL",
    icon: <IoIosFootball />,
    childrens: [
      {
        title: "WORLDCUP",

        path: "/cricket/teams",
        subchildren: [
          {
            title: "IND Vs WI ",
            path: "/cricket/teams/national",
          },
          {
            title: "AUS Vs NWZ",
            path: "/cricket/teams/club",
          },
        ],
      },
    ],
  },
  {
    title: "KABADDI",
    path: "/cricket",
    icon: <MdSportsKabaddi />,
    childrens: [
      {
        title: "IPL",
        path: "/cricket/teams",
        subchildren: [
          {
            title: "CSK Vs MI",
            path: "/cricket/teams/national",
          },
          {
            title: "KKR Vs SRH",
            path: "/cricket/teams/club",
          },
        ],
      },
    ],
  },
];
