import React from "react";
import "../StyleSheet/GAMESCSS/cricketgame.css";
import BallBet from "../CRICKETPACKAGEE/BallBet";
import { cricketMatches } from "../LiveData/gamesData";
import BettingTemp from "../Components/BettingTemp";


const Cricket = () => {
  return (
    <div>
        <BettingTemp value={cricketMatches} />
      {/* <BallBet /> */}
  </div>
  );
};

export default Cricket;
