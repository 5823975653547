import React, { useState } from "react";
// import Pagination from "@mui/material/Pagination";
import CustomPagination from "../AccountStatemets/AccountPagination/CustomPagination.jsx";
// import usePagination from '@mui/material/usePagination';

import Stack from "@mui/material/Stack";
import userAccountDetails from "../AccountStatemets/accountstatement.js";
import "../StyleSheet/accountstatememt.css";

const AccountStatement = () => {
  const [selectedReportType, setSelectedReportType] = useState("");
  const [selectedDates, setSelectedDates] = useState({
    startDate: "",
    endDate: "",
  });
  const [showAccStatement, setShowAccStatement] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  const indexOfLastRecord = currentPage * itemsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - itemsPerPage;
  const currentRecords = userAccountDetails.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(value);
    setCurrentPage(1);
  };

  const handleReportTypeChange = (event) => {
    setSelectedReportType(event.target.value);
  };

  const handleDateChange = (e) => {
    setSelectedDates({ ...selectedDates, [e.target.name]: e.target.value });
  };

  const hanldeShowAcc = () => {
    setShowAccStatement(true);
  };

  const reSetDates = () => {
    setSelectedDates({ startDate: "", endDate: "" });

    alert("I am working..........");
  };

  return (
    <>
      <div className="account-statement-container">
        <h2>Account Statement</h2>
        <div className="report-options-container">
          <div className="report-type-dropdown">
            <select
              id="dropdown"
              value={selectedReportType}
              onChange={handleReportTypeChange}
            >
              <option value="">ALL..</option>
              <option value="BALANCE">BALANCE REPORT</option>
              <option value="GAME">GAME REPORT</option>
              <option value="SETTLEMENT">SETTLEMENT REPORT</option>
            </select>
          </div>
          <div>
            <input
              type="date"
              id="start"
              name="startDate"
              className="custom-date-input"
              onChange={handleDateChange}
              value={selectedDates.startDate}
              min="2018-01-01"
              max="2025-12-31"
            />
          </div>
          <div>
            <input
              type="date"
              id="end"
              name="endDate"
              onChange={handleDateChange}
              value={selectedDates.endDate}
              min="2018-01-01"
              max="2025-12-31"
            />
          </div>
          <div className="btn-act">
            <button onClick={hanldeShowAcc}>GO</button>
            <button onClick={reSetDates}>Reset</button>
          </div>
        </div>
      </div>

      {showAccStatement && (
        <>
          <div className="items-per-page">
            <label>
              Show
              <select
                value={itemsPerPage}
                onChange={(e) =>
                  handleItemsPerPageChange(Number(e.target.value))
                }
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
              </select>
              Entries
            </label>
          </div>
          <div className="account-statement-table">
            <table>
              <thead>
                <tr>
                  <th>DATE</th>
                  <th>CREDIT</th>
                  <th>DEBIT</th>
                  <th>BALANCE</th>
                  <th>DESCRIPTION</th>
                </tr>
              </thead>
              <tbody>
                {currentRecords.map((item, id) => (
                  <tr
                    key={id}
                    className={id % 2 === 0 ? "even-row" : "odd-row"}
                  >
                    <td>{item.date}</td>
                    <td>{item.credit}</td>
                    <td>{item.debit}</td>
                    <td>{item.balance}</td>
                    <td>{item.description}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div>
              <Stack spacing={2}>
                <CustomPagination
                  style={{ left: "50px" }}
                  className="pagination-number"
                  count={Math.ceil(userAccountDetails.length / itemsPerPage)}
                  page={currentPage}
                  onChange={(_e, page) => handlePageChange(page)}
                  variant="outlined"
                  shape="rounded"
                />
              </Stack>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AccountStatement;
